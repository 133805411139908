<template>
  <dashboard-card
    :title="$t('statistics.titles.revenue')"
    class="statistics-revenue tw-border tw-border-solid tw-border-gray-300 tw-bg-white tw-rounded"
  >
    <div class="tw-flex tw-flex-col md:tw-flex-row">
      <div class="statistics-revenue__content tw-relative tw-flex tw-flex-col md:tw-w-1/2 md:tw-mr-6 tw-mb-6 md:tw-mb-0">
        <div class="2sm:tw-w-1/2">
          <ctk-input-select
            v-model="year"
            :items="revenueYears"
            :label="$t('statistics.labels.year')"
            :disabled="$wait.is('fetching revenue statistics')"
            class="tw-w-32 tw-mb-4"
            data-test="year-select"
            required
            @input="changeYear"
          />
        </div>
        <div class="tw-flex tw-flex-col 2sm:tw-flex-row tw-justify-end">
          <div class="tw-flex tw-flex-col tw-w-full 2sm:tw-w-2/3 md:tw-w-4/6 lg:tw-w-1/2 tw-mx-auto 2sm:tw-mx-0">
            <div class="tw-my-auto tw-text-center tw-text-blue-500 tw-bg-gray-100 tw-rounded tw-py-2 tw-px-4 tw-z-10">
              <template
                v-if="$wait.is('fetching revenue statistics')"
              >
                <ui-loader
                  :size="40"
                  background-back="#000"
                  type="pallet"
                  data-test="loader"
                  class="tw-mx-auto tw-my-4"
                />
              </template>
              <template
                v-else
              >
                <div
                  class="tw-text-4xl tw-font-light"
                  data-test="total-revenue"
                  v-text="$options.filters.currency(totalRevenue, currency, $i18n.locale)"
                />
                <div
                  class="tw-text-sm tw-font-bold tw--mt-2"
                  data-test="total-missions"
                  v-text="$tc('statistics.values.missions', totalMissions, {
                    count: totalMissions
                  })"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-flex md:tw-w-1/2">
        <statistics-revenue-chart
          :dataset="revenueDataset"
          :currency="currency"
          :loading="$wait.is('fetching revenue statistics')"
          class="tw-w-full"
          data-test="revenue-chart"
        />
      </div>
    </div>
  </dashboard-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { Company } from '@/resources'

  import CtkInputSelect from '@/components/CtkInputs/CtkInputSelect'
  import DashboardCard from '@/views/Common/Dashboard/components/DashboardCard'
  import StatisticsRevenueChart from './_subs/StatisticsRevenueChart'

  /**
   * @module component - StatisticsRevenue
   */
  export default {
    name: 'StatisticsRevenue',
    components: {
      DashboardCard,
      CtkInputSelect,
      StatisticsRevenueChart
    },
    computed: {
      ...mapGetters('auth', [
        'getCid',
        'getUserInfos'
      ]),
      revenueYears () {
        const { registered_at: registeredAt } = this.getUserInfos
        const registeredYear = new Date(registeredAt).getFullYear()
        const yearsOffset = new Date().getFullYear() - registeredYear

        return Array.from(new Array(yearsOffset + 1))
          .map((_, v) => new Date().getFullYear() - v)
          .map(v => ({
            text: v,
            value: v
          }))
      },
      /**
       * Returns the sum of all the revenue
       * @function totalRevenue
       * @returns {number}
       */
      totalRevenue () {
        return Array.from(this.revenueDataset)
          .map(v => v.revenue)
          .reduce((a, b) => a + b, 0)
      },
      /**
       * Returns the sum of all missions
       * @function totalMissions
       * @returns {number}
       */
      totalMissions () {
        return Array.from(this.revenueDataset)
          .map(v => v.mission_count || 0)
          .reduce((a, b) => a + b, 0)
      }
    },
    data () {
      return {
        loading: false,
        currency: 'EUR',
        year: new Date().getFullYear(),
        revenueDataset: []
      }
    },
    async mounted () {
      await this.fetchYear(new Date().getFullYear())
    },
    methods: {
      async pause (timeout) {
        return new Promise(resolve => setTimeout(() => {
          resolve()
        }, timeout))
      },
      changeYear (year) {
        if (this.$matomo) {
          this.$matomo.trackEvent('Statistics', 'Change Period')
        }

        this.fetchYear(year)
      },
      fetchYear (year) {
        this.$wait.start('fetching revenue statistics')
        return Company.statistics({
          cid: this.getCid
        }, {}, {
          params: {
            start_date: `${year}-01`,
            end_date: `${year}-12`
          }
        })
          .then(async res => {
            /**
             * Add a timeout on purpose to avoid the visual jump on the graph
             */
            await this.pause(1250)
            this.revenueDataset = res.data.data_points
            this.currency = res.data.currency

            return res
          })
          .catch(() => {
            this.revenueDataset = []
          })
          .finally(() => {
            this.$wait.end('fetching revenue statistics')
          })
      }
    }
  }
</script>

<style lang="scss" scoped>

  .statistics-revenue {
    &__content {
      min-height: 200px;

      &::before {
        content: '';
        position: absolute;
        left: -40px;
        bottom: -30px;
        width: 70%;
        height: 200px;
        background-image: url('~@/assets/img/illustrations/welcome_reversed.svg');
        background-repeat: no-repeat;
        background-position: center right;
        opacity: 0.8;
        z-index: 0;
      }

      @media only screen and (max-width: $breakpoint-mobile-l) {
        &::before {
          left: -1rem;
          width: 100%;
        }
      }

      @media only screen and (max-width: $breakpoint-tablet) {
        &::before {
          bottom: -1rem;
        }
      }
    }
  }

</style>
