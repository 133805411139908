<template>
  <div class="statistics tw-px-4 tw-pt-8 tw-pb-16 md:tw-px-0">
    <div class="ctk-container">
      <h1
        data-test="title"
        class="tw-text-2xl tw-font-medium tw-mb-3"
        v-text="$t('statistics.titles.main')"
      />

      <statistics-revenue
        data-test="revenue"
      />
    </div>
  </div>
</template>

<script>
  import store from '@/store'

  import StatisticsRevenue from './components/StatisticsRevenue'

  /**
   * @module view - Statistics
   */
  export default {
    name: 'Statistics',
    metaInfo () {
      return {
        title: this.$t('statistics.titles.main')
      }
    },
    components: {
      StatisticsRevenue
    },
    async beforeRouteEnter (to, from, next) {
      if (store.getters.isUserShipper) {
        next({
          name: 'Dashboard'
        })
        return
      }

      store.dispatch('setAppReady', true)
      next()
    }
  }
</script>

<style lang="scss" scoped>

  .statistics {
    overflow-y: scroll !important;
  }

</style>
