<template>
  <div class="statistics-revenue-chart tw-relative">
    <canvas
      id="chart"
      height="250"
      width="100%"
    />
  </div>
</template>

<script>
  import animejs from 'animejs'
  import Chart from 'chart.js'

  /**
   * @module component - StatisticsRevenueChart
   * @param {Array} dataset
   * @param {string} [currency="EUR"]
   * @param {boolean} [loading=false]
   */
  export default {
    name: 'StatisticsRevenueChart',
    props: {
      dataset: {
        type: Array,
        required: true
      },
      currency: {
        type: String,
        required: true
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        chart: null,
        loadingDataset: []
      }
    },
    computed: {
      formattedDataset () {
        return this.loading ? this.loadingDataset : this.dataset
      }
    },
    beforeDestroy () {
      if (this.chart) this.chart.destroy()
      window.removeEventListener('resize', this.updateTooltipPosition)

      const tooltipContainer = document.querySelector('#chartjs-tooltip-container')
      if (!tooltipContainer) {
        document.body.removeChild(tooltipContainer)
      }

      /**
       * Remove the previously added scroll event from the statistics view
       * TODO: Find a better way to handle this edge case
       */
      const statisticsView = document.querySelector('.statistics.main-container')
      if (statisticsView) {
        statisticsView.removeEventListener('scroll', this.updateTooltipPosition)
      }
    },
    mounted () {
      const _this = this

      /**
       * Initialize chart tooltip container
       */
      let tooltipContainer = document.querySelector('#chartjs-tooltip-container')
      if (!tooltipContainer) {
        tooltipContainer = document.createElement('div')
        tooltipContainer.id = 'chartjs-tooltip-container'

        document.body.appendChild(tooltipContainer)
      }

      /**
       * Loading dataset
       */
      const values = Array.from(new Array(12)).map(() => ({
        revenue: 0
      }))

      animejs({
        targets: values,
        revenue: [
          {
            value: 0, duration: 500
          },
          {
            value: 100, duration: 500
          },
          {
            value: 0, duration: 500
          }
        ],
        loop: true,
        delay: (el, i) => i * 50,
        endDelay: (el, i, l) => (l - i) * 50,
        easing: 'easeInOutSine',
        update: () => {
          this.loadingDataset = values
        }
      })

      const canvas = document.querySelector('#chart')
      const ctx = canvas.getContext('2d')

      const gradientFill = ctx.createLinearGradient(0, 0, 0, 200)
      gradientFill.addColorStop(0, '#277696')
      gradientFill.addColorStop(1, '#3EA0BA')

      const gradientHoverFill = ctx.createLinearGradient(0, 0, 0, 200)
      gradientHoverFill.addColorStop(0, '#96BF31')
      gradientHoverFill.addColorStop(1, '#BAE258')

      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.$moment.monthsShort().map(month => this.$options.filters.capitalize(month)),
          datasets: [
            {
              label: '',
              backgroundColor: this.loading ? 'rgba(0, 0, 0, 0.1)' : gradientFill,
              hoverBackgroundColor: this.loading ? 'rgba(0, 0, 0, 0.1)' : gradientHoverFill,
              data: this.formattedDataset.map(dataset => dataset.revenue)
            }
          ]
        },
        options: {
          legend: {
            display: false
          },
          tooltips: {
            enabled: false,
            callbacks: {
              afterBody (dataSets) {
                const index = dataSets[0].index

                /**
                 * The mission_count here can be undefined since the datapoint is a fake one
                 * due to the loading.
                 */
                const dataPoint = _this.dataset[index]
                if (_this.loading || !dataPoint || (dataPoint && typeof _this.dataset[index].mission_count === 'undefined')) return false
                return dataPoint.mission_count
              },
              beforeBody (dataSets, data) {
                const index = dataSets[0].index
                const value = data.datasets[0].data[index]

                return value
              }
            },
            custom: this.customTooltips
          },
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              ticks: {
                maxTicksLimit: 6,
                ...this.loading ? {
                  min: 0,
                  max: 100
                } : {
                  min: 0
                },
                callback (value) {
                  return _this.loading
                    ? value
                    : _this.$options.filters.currency(value, _this.currency, _this.$i18n.locale)
                }
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          }
        }
      })

      window.addEventListener('resize', this.updateTooltipPosition)

      /**
       * Add scroll event on the statistics view to update the tooltip position
       * TODO: Find a better way to handle this edge case
       */
      const statisticsView = document.querySelector('.statistics.main-container')
      if (statisticsView) {
        statisticsView.addEventListener('scroll', this.updateTooltipPosition)
      }
    },
    methods: {
      updateTooltipPosition () {
        const canvasBounds = this.chart.canvas.getBoundingClientRect()
        const tooltipEl = document.getElementById('chartjs-tooltip')

        if (tooltipEl) {
          tooltipEl.style.transform = `translate(${canvasBounds.left}px, ${canvasBounds.top}px)`
          tooltipEl.style.opacity = 0
        }
      },
      async customTooltips (tooltip) {
        const canvasBounds = this.chart.canvas.getBoundingClientRect()

        // Tooltip Element
        let tooltipEl = document.getElementById('chartjs-tooltip')
        const newlyCreated = !tooltipEl

        if (!tooltipEl) {
          tooltipEl = document.createElement('div')
          tooltipEl.id = 'chartjs-tooltip'
          tooltipEl.innerHTML = '<div></div>'
          tooltipEl.style.transform = `translate(${canvasBounds.left}px, ${canvasBounds.top}px)`
          document.querySelector('#chartjs-tooltip-container').appendChild(tooltipEl)
        }

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
          tooltipEl.style.opacity = 0
          return
        }

        if (tooltip.beforeBody) {
          tooltipEl.innerHTML = `
            <div class="tw-font-medium tw-text-sm">
              ${this.$options.filters.currency(tooltip.beforeBody[0], this.currency, this.$i18n.locale)}
            </div>
            <div class="tw-font-normal tw-text-xs">
              ${this.$tc('statistics.values.missions', tooltip.afterBody[0], { count: tooltip.afterBody[0] })}
            </div>
          `
        }

        await this.$nextTick()
        const tooltipBounds = tooltipEl.getBoundingClientRect()
        const tooltipSize = {
          height: Math.min(tooltipBounds.height || 0, 51),
          width: Math.min(tooltipBounds.width || 0, 66)
        }

        const tooltipPosition = {
          x: (tooltip.caretX) - (tooltipSize.width / 2),
          y: (tooltip.caretY) - tooltipSize.height - 8
        }

        /**
         * Recalculate the tooltip position to ensure it does not overflow it's container (aka
         * the canvas), by keeping it in the boundaries.
         */
        const tooltipMaxBounds = {
          left: canvasBounds.left + Math.max(0, Math.min(canvasBounds.width - tooltipSize.width - 8, tooltipPosition.x)),
          top: canvasBounds.top + tooltipPosition.y - (newlyCreated ? 16 : 0)
        }

        // Display, position, and set styles
        tooltipEl.style.backgroundColor = '#96BF31'
        tooltipEl.style.opacity = 1
        tooltipEl.style.transform = `translate(${tooltipMaxBounds.left}px, ${tooltipMaxBounds.top}px)`
        tooltipEl.style.fontFamily = tooltip._bodyFontFamily
        tooltipEl.style.fontSize = tooltip.bodyFontSize + 'px'
        tooltipEl.style.fontStyle = tooltip._bodyFontStyle
        tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px'
      }
    },
    watch: {
      formattedDataset: {
        deep: true,
        immediate: true,
        handler (v) {
          const _this = this
          if (!this.chart) return false

          const canvas = document.querySelector('#chart')
          const ctx = canvas.getContext('2d')

          const gradientFill = ctx.createLinearGradient(0, 0, 0, 200)
          gradientFill.addColorStop(0, '#277696')
          gradientFill.addColorStop(1, '#3EA0BA')

          const gradientHoverFill = ctx.createLinearGradient(0, 0, 0, 200)
          gradientHoverFill.addColorStop(0, '#96BF31')
          gradientHoverFill.addColorStop(1, '#BAE258')

          this.chart.data.datasets[0] = {
            ...this.chart.data.datasets[0],
            data: v.map(a => a.revenue),
            backgroundColor: this.loading ? 'rgba(0, 0, 0, 0.1)' : gradientFill,
            hoverBackgroundColor: this.loading ? 'rgba(0, 0, 0, 0.1)' : gradientHoverFill
          }

          /**
           * Disable or re-create the tooltip if we're in the loading state.
           */
          if (this.loading) {
            this.chart.options.tooltips.custom = () => {}
          } else {
            this.chart.options.tooltips.custom = this.customTooltips
          }

          this.chart.options.scales.yAxes[0].ticks = {
            maxTicksLimit: 6,
            ...this.loading ? {
              min: 0,
              max: 100
            } : {
              min: 0
            },
            callback (value) {
              return _this.loading
                ? value
                : _this.$options.filters.currency(value, _this.currency, _this.$i18n.locale, true)
            }
          }

          this.chart.update()
        }
      }
    }
  }
</script>

<style lang="scss">

  .statistics-revenue-chart {
    height: 250px;

    canvas {
      user-select: none;
    }
  }

  #chartjs-tooltip,
  #chartjs-tooltip-container {
    pointer-events: none;
    left: 0;
    top: 0;
  }

  #chartjs-tooltip {
    opacity: 1;
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    border-radius: 3px;
    transition: all 0.1s ease;
    transform: translate(-50%, 0);
  }

  #chartjs-tooltip-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
  }

  .chartjs-tooltip-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }

</style>
